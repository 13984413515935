import { actions } from '@buffer-mono/async-data-fetch'
import { actionTypes as dateActionTypes } from '~/date-picker'
import { actionTypes as profileActionTypes } from '~/profile-selector'
import { actionTypes } from './reducer'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import {
  actions as exportActions,
  actionTypes as exportActionTypes,
} from '~/png-export'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import dayTimezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(dayTimezone)

// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
const getSelectedProfileTimezone = (profiles, selectedProfileId) => {
  // @ts-expect-error TS(2686) FIXME: 'moment' refers to a UMD global, but the current f... Remove this comment to see the full error message
  if (!selectedProfileId) return moment.tz.guess()
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  const profile = profiles.find((p) => p.id === selectedProfileId)
  return profile.timezone
}

const formatDataForCSVExport = (
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedMetrics' implicitly has a... Remove this comment to see the full error message
  { selectedMetrics, daily },
  // @ts-expect-error TS(7031) FIXME: Binding element 'profiles' implicitly has an 'any'... Remove this comment to see the full error message
  { profiles, selectedProfileId },
) => {
  if (!selectedMetrics) return null
  const data = {
    date: [],
    [selectedMetrics.primary.label]: [],
    [selectedMetrics.secondary.label]: [],
  }
  const timezone = getSelectedProfileTimezone(profiles, selectedProfileId)
  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  daily.forEach((item) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'metric' implicitly has an 'any' type.
    item.metrics.map((metric) => {
      if (metric.label == selectedMetrics.primary.label) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data[selectedMetrics.primary.label].push(metric.value)
      }
      if (metric.label == selectedMetrics.secondary.label) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        data[selectedMetrics.secondary.label].push(metric.value)
      }
    })
    const day = item.day
    const formattedDay = dayjs
      .unix(day / 1000)
      .tz(timezone)
      .format('MM/DD/YYYY')
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    data.date.push(formattedDay)
  })
  return data
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case dateActionTypes.SET_DATE_RANGE:
      if (getState().profiles.selectedProfileId) {
        dispatch(
          actions.fetch({
            name: 'stories_daily',
            args: {
              profileId: state.profiles.selectedProfileId,
              startDate: action.startDate,
              endDate: action.endDate,
            },
          }),
        )
      }
      break
    case profileActionTypes.SELECT_PROFILE:
      if (action.profile.service === 'instagram') {
        dispatch(
          actions.fetch({
            name: 'stories_daily',
            args: {
              profileId: action.profile.id,
              startDate: state.date.startDate,
              endDate: state.date.endDate,
            },
          }),
        )
      }
      break
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'stories_daily',
          args: {
            profileId: state.profiles.selectedProfileId,
            startDate: state.date.startDate,
            endDate: state.date.endDate,
          },
        }),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      if (state.profiles.selectedProfile?.service === 'instagram') {
        dispatch(
          exportCSVActions.exportChart(
            'stories-daily',
            formatDataForCSVExport(state.storiesDaily, state.profiles),
          ),
        )
      }
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-stories-daily',
          'stories-daily',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
