import React from 'react'
import { gql } from '@apollo/client'

enum InstagramAuthMethod {
  facebookLogin = 'facebookLogin',
  instagramLogin = 'instagramLogin',
}

type Channel = {
  id: string
  avatar: string
  service: string
  serviceId: string
  type: string
  name: string
  isLocked: boolean
  isDisconnected: boolean
  organizationId: string
  apiVersion?: InstagramAuthMethod
}

type AccountContextValue = {
  currentOrganization: {
    channels: Channel[]
  }
  email: string
}

export const AccountContext = React.createContext<AccountContextValue>({
  currentOrganization: {
    channels: [] as Channel[],
  },
  email: '',
})

export const useAccount = (): AccountContextValue => {
  const context = React.useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountContext')
  }
  return context
}

export const GET_ACCOUNT = gql`
  query GetAccountChannels {
    account {
      id
      featureFlips
      currentOrganization {
        featureFlips
        id
        isOneBufferOrganization
        name
        role
        canEdit
        billing {
          id
          canAccessAnalytics
          ... on OBBilling {
            canStartTrial
            subscription {
              plan {
                id
              }
              trial {
                isActive
              }
            }
          }
          ... on MPBilling {
            billingRedirectUrl
            subscriptions {
              plan
              product
            }
          }
        }
        channels(product: analyze) {
          id
          avatar
          service
          serviceId
          type
          name
          isLocked
          isDisconnected
          organizationId
          apiVersion
        }
      }
      organizations {
        id
        channels(product: analyze) {
          service
          name
          id
          organizationId
        }
      }
    }
  }
`
